import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useAuthContext} from "../contexts/authContext";
import {useNavigate} from "react-router-dom";

export const AuthGuard = (props) => {
  const navigate = useNavigate();
  const {children} = props;
  const {isAuthenticated} = useAuthContext();
  const [checked, setChecked] = useState(false);

  // Only do authentication check on component mount.
  // This flow allows you to manually redirect the user after sign-out, otherwise this will be
  // triggered and will automatically redirect to sign-in page.

  useEffect(
    () => {
      if (!isAuthenticated) {
        navigate("/auth/login")
      } else {
        setChecked(true);
      }
    },
    [isAuthenticated, navigate]
  );

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};
