import {lazy} from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const TransactionsList = Loadable(lazy(() => import('views/transactions/transactions')));
const FailedTransactionsList = Loadable(lazy(() => import('views/transactions/failed-transactions')));
const PositionsList = Loadable(lazy(() => import('views/transactions/positions')));
const FilesList = Loadable(lazy(() => import('views/transactions/files')));

// ==============================|| MAIN ROUTING ||============================== //


const TransactionsRoutes = {
  path: '/transactions',
  element: <MainLayout/>,
  children: [
    {
      path: '/transactions/',
      element: <TransactionsList/>
    },
    {
      path: '/transactions/failed/',
      element: <FailedTransactionsList/>
    },
    {
      path: '/transactions/positions/',
      element: <PositionsList/>
    },
    {
      path: '/transactions/files/',
      element: <FilesList/>
    }

  ]
};

export default TransactionsRoutes;
