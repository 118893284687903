import {createContext, useContext, useEffect, useReducer, useRef} from "react";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";
import API from "../utils/API";
import {decodeToken, isExpired} from "react-jwt";

const HANDLERS = {
  INITIALIZE: 'INITIALIZE',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT'
};

const initialState = {
  isAuthenticated: false,
  isLoading: true,
  user: null,
};

const handlers = {
  [HANDLERS.INITIALIZE]: (state, action) => {
    const user = action.payload;

    return {
      ...state,
      isAuthenticated: action.isAuthenticated,
      isLoading: false,
      user
      }
  },
  [HANDLERS.SIGN_IN]: (state, action) => {
    const user = action.payload;

    return {
      ...state,
      isAuthenticated: action.isAuthenticated,
      user
    };
  },
  [HANDLERS.SIGN_OUT]: (state, action) => {
    const user = action.payload;

    return {
      ...state,
      isAuthenticated: action.isAuthenticated,
      user
    };
  }
};

const reducer = (state, action) => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

export const AuthContext = createContext({undefined});

export const AuthProvider = (props) => {
  const navigate = useNavigate();
  const {children} = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialized = useRef(false);

  const initialize = async () => {
    // Prevent from calling twice in development mode with React.StrictMode enabled
    if (initialized.current) {
      return;
    }

    initialized.current = true;

    let isAuthenticated = false;

    let accessToken = localStorage.getItem("accessToken")


    if (accessToken) {
      if (!isExpired(accessToken)) {
        isAuthenticated = true;
        API.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
      } else {
        await tokenRefresh()
        isAuthenticated = true;
      }
    }

    if (isAuthenticated) {
      const user = JSON.parse(localStorage.getItem("user"))

      dispatch({
        type: HANDLERS.INITIALIZE,
        payload: user,
        isAuthenticated: isAuthenticated
      });

    } else {
      dispatch({
        payload: {permissions: []},
        type: HANDLERS.INITIALIZE,
        isAuthenticated: isAuthenticated
      });
    }

  };

  useEffect(
    () => {
      initialize();
    }
  );


  const signIn = async (accessToken, refreshToken, permissions) => {
    const decodedAccessToken = decodeToken(accessToken);

    const user = {
      email: decodedAccessToken.sub,
      permissions: permissions
    };

    localStorage.setItem("user", JSON.stringify(user))
    localStorage.setItem("accessToken", accessToken)
    localStorage.setItem("refreshToken", refreshToken)

    API.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`

    navigate("/")

    dispatch({
      type: HANDLERS.SIGN_IN,
      payload: user,
      isAuthenticated: true
    });
  };

  const tokenRefresh = async () => {
    let refreshThreshold = 5 * 60
    let nowTimestampSeconds = new Date().valueOf() / 1000

    const accessToken = localStorage.getItem("accessToken")
    const refreshToken = localStorage.getItem("refreshToken")

    const decodedRefreshToken = decodeToken(refreshToken);
    const decodedAccessToken = decodeToken(accessToken);

    if (decodedRefreshToken.exp <= nowTimestampSeconds) {
      return signOut();
    }

    if ((decodedAccessToken.exp - refreshThreshold) < nowTimestampSeconds) {

      API.post("/auth/refresh", {
        refresh_token: refreshToken,
      }).then((response) => {
        if (response.status === 200) {
          let accessToken = response.data.access_token
          localStorage.setItem("accessToken", accessToken)
          API.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
        }
      }).catch((error) => {
        return signOut();
      })
    }
  }

  const signOut = async () => {
    localStorage.clear()
    API.defaults.headers.common['Authorization'] = null
    navigate("/auth/login")

    dispatch({
      type: HANDLERS.SIGN_OUT,
      isAuthenticated: false,
      payload: {permissions: []}
    });
  }


  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signOut,
        tokenRefresh
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node
};

export const AuthConsumer = AuthContext.Consumer;

export const useAuthContext = () => useContext(AuthContext);
