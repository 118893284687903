import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import TransactionsRoutes from './TransactionsRoutes';
import UsersRoutes from "./UsersRoutes";
import ClientRoutes from "./ClientRoutes";
import AdditionalFilesRoutes from "./AdditionalFilesRoutes";
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {

  const routes = [
      AuthenticationRoutes,
      MainRoutes,
      AdditionalFilesRoutes,
      ClientRoutes,
      TransactionsRoutes,
      UsersRoutes
  ]

  return useRoutes(routes);
}
