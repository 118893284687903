import {IconDashboard} from '@tabler/icons-react';

const icons = {IconDashboard};

const dashboard = {
    id: 'dashboard',
    title: 'Home',
    type: 'collapse',
    icon: icons.IconDashboard,
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            breadcrumbs: false
        }
    ]
};

export default dashboard;
