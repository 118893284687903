import {lazy} from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
// dashboard routing
const UsersList = Loadable(lazy(() => import('views/users/users-list')));

// ==============================|| MAIN ROUTING ||============================== //


const UsersRoutes = {
  path: '/users',
  element: <MainLayout/>,
  children: [
    {
      path: '/users/',
      element: <UsersList/>
    }

  ]
};
export default UsersRoutes;
