import AttachFile from '@mui/icons-material/AttachFile';
import {useAuth} from "../hooks/useAuth";
import {permissionsEnum} from "../store/permissions";

const AdditionalFilesMenuItems = () => {
    const {user} = useAuth()

    let childrens = []

    if (user.permissions.includes(permissionsEnum.additionalFilesRead)) {
        childrens.push(
            {
                id: 'additional-files-list',
                title: 'Additional files',
                type: 'item',
                url: '/additional_files/',
                breadcrumbs: false
            },
        )
    }

    return {
        id: 'additional-files',
        title: 'Additional files',
        icon: AttachFile,
        type: 'collapse',
        children: childrens
    }
}
export default AdditionalFilesMenuItems;
