import Paid from '@mui/icons-material/Paid';
import {useAuth} from "../hooks/useAuth";
import {permissionsEnum} from "../store/permissions";


const TransactionsMenuItems = () => {
  const {user} = useAuth()

  let childrens = []

  if (user.permissions.includes(permissionsEnum.transactionsRead)) {
    childrens.push(
      {
        id: 'transactions-list',
        title: 'Transactions',
        type: 'item',
        url: '/transactions/',
        breadcrumbs: false
      }
    )
  }

  if (user.permissions.includes(permissionsEnum.failedTransactionsRead)) {
    childrens.push(
      {
        id: 'failed-transactions-list',
        title: 'Failed transactions',
        type: 'item',
        url: '/transactions/failed/',
        breadcrumbs: false
      }
    )
  }

  if (user.permissions.includes(permissionsEnum.positionsRead)) {
    childrens.push(
      {
        id: 'positions-list',
        title: 'Positions',
        type: 'item',
        url: '/transactions/positions/',
        breadcrumbs: false
      }
    )
  }

  if (user.permissions.includes(permissionsEnum.filesRead)) {
    childrens.push(
      {
        id: 'files-list',
        title: 'Files',
        type: 'item',
        url: '/transactions/files/',
        breadcrumbs: false
      }
    )
  }

  return {
    id: 'transactions',
    title: "Transactions",
    type: 'collapse',
    icon: Paid,
    children: childrens
  }
}

export default TransactionsMenuItems;
