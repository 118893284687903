import { useEffect } from 'react';
import {useAuthContext} from "../contexts/authContext";
import {useAuth} from "../hooks/useAuth";

export const RefreshGuard = () => {
  const { isAuthenticated } = useAuthContext();
  const auth = useAuth();

  useEffect(
    () => {
      const timer = setInterval(() => {
        if (isAuthenticated === true){
          auth.tokenRefresh();
        }
      }, 60000);
      return () => clearInterval(timer)
    }
  );
};
