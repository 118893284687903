import {lazy} from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const ClientMansory = Loadable(lazy(() => import('views/client/client')));

// ==============================|| MAIN ROUTING ||============================== //

const ClientRoutes = {
    path: '/client',
    element: <MainLayout/>,
    children: [
        {
            path: '/client/',
            element: <ClientMansory/>
        },
    ]
};

export default ClientRoutes;
