import dashboard from './dashboard';
import client from './client';
import transactions from './transactions';
import additionalFiles from "./additional-files";
import users from "./users";
import {useAuth} from "../hooks/useAuth";
import {permissionsEnum} from "../store/permissions";

// ==============================|| MENU ITEMS ||============================== //

const MenuItems = () => {
  const {user} = useAuth()

  let menuItems = [dashboard]

  if (user.permissions.includes(permissionsEnum.transactions)) {
    menuItems.push(transactions())
  }

  if (user.permissions.includes(permissionsEnum.additionalFiles)) {
    menuItems.push(additionalFiles())
  }

  if (user.permissions.includes(permissionsEnum.client)) {
    menuItems.push(client())
  }

  if (user.permissions.includes(permissionsEnum.users)) {
    menuItems.push(users())
  }


  return {
    items: menuItems
  }
}

export default MenuItems;
