import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const AccountSettings = Loadable(lazy(() => import('views/pages/account-settings')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      index: true,
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: "/account-settings/",
      element: <AccountSettings />
    }
  ]
};

export default MainRoutes;
