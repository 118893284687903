import Group from '@mui/icons-material/Group';
import {useAuth} from "../hooks/useAuth";
import {permissionsEnum} from "../store/permissions";

const UsersMenuItems = () => {
  const {user} = useAuth()

  let childrens = []

  if (user.permissions.includes(permissionsEnum.usersRead)) {
    childrens.push(
      {
        id: 'users',
        title: 'Users',
        type: 'item',
        url: '/users/',
        breadcrumbs: false
      }
    )
  }

  return {
    id: 'users',
    title: 'Users',
    type: 'collapse',
    icon: Group,
    children: childrens
  }
}

export default UsersMenuItems;
