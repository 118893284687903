import {lazy} from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const AdditionalFilesList = Loadable(lazy(() => import('views/additional-files')));

// ==============================|| MAIN ROUTING ||============================== //

const AdditionalFilesRoutes = {
    path: '/additional_files',
    element: <MainLayout/>,
    children: [
        {
            path: '/additional_files/',
            element: <AdditionalFilesList/>
        },
    ]
};

export default AdditionalFilesRoutes;
