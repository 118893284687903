import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import {useAuth} from "../hooks/useAuth";
import {permissionsEnum} from "../store/permissions";


const ClientMenuItems = () => {
  const {user} = useAuth()

  let childrens = []

  if (user.permissions.includes(permissionsEnum.client)) {
    childrens.push(
        {
            id: 'client-client',
            title: 'Client',
            type: 'item',
            url: '/client/',
            breadcrumbs: false
        },
    )
  }

  return {
    id: 'client',
    title: 'Client',
    icon: SwitchAccountIcon,
    type: 'collapse',
    children: childrens
  }
}

export default ClientMenuItems;
